const DefaultLink = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.78659 7.21302C9.10402 6.53074 8.17844 6.14746 7.21335 6.14746C6.24826 6.14746 5.32268 6.53074 4.64011 7.21302L2.06604 9.78625C1.38347 10.4688 1 11.3946 1 12.3599C1 13.3252 1.38347 14.251 2.06604 14.9336C2.74862 15.6161 3.67439 15.9996 4.6397 15.9996C5.605 15.9996 6.53077 15.6161 7.21335 14.9336L8.49997 13.6469"
        stroke="white"
        strokeWidth="1.5555"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.21338 9.78658C7.89594 10.4689 8.82153 10.8521 9.78662 10.8521C10.7517 10.8521 11.6773 10.4689 12.3599 9.78658L14.9339 7.21335C15.6165 6.53077 16 5.605 16 4.6397C16 3.67439 15.6165 2.74862 14.9339 2.06604C14.2513 1.38347 13.3256 1 12.3603 1C11.395 1 10.4692 1.38347 9.78662 2.06604L8.5 3.35266"
        stroke="white"
        strokeWidth="1.55551"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DefaultLink;
