const MergeIcon = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="25px"
      height="25px"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="white" stroke="none">
        <path
          d="M508 4869 c-60 -9 -141 -53 -180 -98 -17 -19 -43 -60 -57 -91 l-26
      -55 0 -595 c0 -551 1 -599 18 -648 25 -70 101 -152 169 -183 l53 -24 615 0
      c610 0 615 0 662 22 60 27 127 95 159 158 l24 50 0 615 0 615 -24 53 c-29 65
      -113 143 -178 167 -43 16 -102 18 -618 20 -314 1 -591 -2 -617 -6z m470 -481
      l-3 -243 -242 -3 -243 -2 0 225 c0 212 1 226 20 245 19 19 33 20 245 20 l225
      0 -2 -242z m711 215 c20 -25 21 -36 19 -242 l-3 -216 -242 -3 -243 -2 0 245 0
      245 224 0 225 0 20 -27z m-711 -945 l-3 -243 -216 -3 c-206 -2 -217 -1 -242
      19 l-27 20 0 225 0 224 245 0 245 0 -2 -242z m732 32 c0 -132 -4 -218 -11
      -233 -19 -42 -47 -47 -269 -47 l-210 0 0 245 0 245 245 0 245 0 0 -210z"
        />
        <path
          d="M3565 4870 c-129 -4 -179 -9 -207 -22 -57 -26 -133 -102 -159 -161
      l-24 -52 0 -615 0 -615 24 -50 c32 -63 93 -124 156 -156 l50 -24 615 0 615 0
      53 24 c68 31 144 113 169 183 17 49 18 97 18 648 l0 595 -26 55 c-37 79 -81
      125 -154 161 l-64 31 -448 2 c-246 0 -524 -1 -618 -4z m335 -485 l0 -245 -242
      2 -243 3 -3 216 c-2 206 -1 217 19 242 l20 27 225 0 224 0 0 -245z m710 225
      c19 -19 20 -33 20 -245 l0 -225 -242 2 -243 3 -3 243 -2 242 225 0 c212 0 226
      -1 245 -20z m-710 -955 l0 -245 -210 0 c-132 0 -218 4 -233 11 -42 19 -47 47
      -47 269 l0 210 245 0 245 0 0 -245z m730 21 l0 -225 -27 -20 c-25 -20 -36 -21
      -242 -19 l-216 3 -3 243 -2 242 245 0 245 0 0 -224z"
        />
        <path
          d="M290 2773 c-19 -17 -35 -44 -40 -68 -12 -52 -12 -529 0 -623 19 -145
      104 -263 238 -329 l76 -38 179 -3 178 -4 -89 -92 c-93 -96 -110 -128 -97 -186
      3 -16 22 -43 41 -60 39 -34 87 -40 132 -16 46 24 420 408 427 439 17 66 5 82
      -213 299 -114 113 -218 211 -231 218 -54 27 -145 -22 -157 -86 -11 -58 4 -85
      99 -181 l91 -93 -145 0 c-166 0 -207 12 -255 76 l-29 37 -5 326 c-4 234 -9
      332 -17 348 -39 68 -128 85 -183 36z"
        />
        <path
          d="M4686 2779 c-50 -39 -51 -44 -56 -390 l-5 -326 -29 -37 c-48 -64 -89
      -76 -255 -76 l-145 0 91 93 c95 96 110 123 99 181 -12 64 -103 113 -157 86
      -13 -7 -117 -105 -231 -218 -218 -217 -230 -233 -213 -299 7 -31 381 -415 427
      -439 92 -48 199 40 174 142 -4 14 -47 68 -97 119 l-90 93 178 4 179 3 76 38
      c134 66 219 184 238 329 12 94 12 571 0 623 -19 85 -119 125 -184 74z"
        />
        <path
          d="M1684 2666 c-87 -28 -158 -95 -201 -191 -17 -38 -18 -98 -18 -1005 0
      -907 1 -968 18 -1017 26 -73 92 -143 169 -179 l63 -29 810 0 c446 0 834 3 863
      7 135 18 236 118 262 260 7 36 10 378 8 989 l-3 934 -26 56 c-33 69 -89 127
      -155 160 l-49 24 -850 2 c-673 1 -859 -1 -891 -11z m1700 -247 l26 -20 0 -225
      0 -224 -850 0 -850 0 0 224 0 225 26 20 c27 21 33 21 824 21 791 0 797 0 824
      -21z m-944 -954 l0 -245 -365 0 -365 0 0 245 0 245 365 0 365 0 0 -245z m970
      0 l0 -245 -365 0 -365 0 0 245 0 245 365 0 365 0 0 -245z m-970 -730 l0 -245
      -343 0 c-294 0 -346 2 -365 16 -22 15 -22 19 -22 245 l0 229 365 0 365 0 0
      -245z m970 20 c0 -212 -1 -226 -20 -245 -19 -19 -33 -20 -365 -20 l-345 0 0
      245 0 245 365 0 365 0 0 -225z"
        />
      </g>
    </svg>
  );
};

export default MergeIcon;
