const ZoomIcon = () => {
  return (
    <svg width="22" height="13" viewBox="0 0 22 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.605957 2.12533C0.605957 1.73855 0.764294 1.36762 1.04614 1.09413C1.32798 0.820638 1.71024 0.666992 2.10882 0.666992H11.126C12.3217 0.666992 13.4685 1.12793 14.314 1.9484C15.1596 2.76887 15.6346 3.88167 15.6346 5.04199V10.8753C15.6346 11.2621 15.4762 11.633 15.1944 11.9065C14.9126 12.18 14.5303 12.3337 14.1317 12.3337H5.11454C3.91879 12.3337 2.77202 11.8727 1.92649 11.0523C1.08097 10.2318 0.605957 9.11898 0.605957 7.95866V2.12533Z"
        fill="white"
      />
      <path
        d="M16.386 6.13872C16.3857 5.43171 16.6501 4.74866 17.1299 4.21663L19.6697 1.39913C20.3565 0.6408 21.646 1.11038 21.646 2.11955V10.8812C21.646 11.8918 20.3566 12.3614 19.6712 11.6016L17.1299 8.78413C16.6504 8.25249 16.3861 7.57002 16.386 6.86351V6.13872Z"
        fill="white"
      />
    </svg>
  );
};

export default ZoomIcon;
